<template>
    <div class="appMain">
		<router-view />
	</div>
</template>

<script>
export default {
    name: "AppMain",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
.appMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
</style>
