<template>
	<div class="footerContainer">
		<div class="footer">
			<div class="left">
				<img class="logo" src="@/assets/footer/logo2.png" alt="">
				<img class="line" src="@/assets/footer/line.png" alt="">
				<div class="contactWay">
					<div class="weChat imgBox">
						<img class="weChatImg" src="@/assets/footer/weChat.png" alt="">
						<img class="ImgAC" src="@/assets/footer/weChatAC.png" alt="">
						<div class="QRcodeBox">
							<div class="arrow"></div>
							<img class="weChatQRcode" src="@/assets/footer/weChatQRcode.png" alt="">
							<span>扫一扫  关注进财猫微信公众号</span>
						</div>
					</div>
					<div class="weChat-com imgBox">
						<img class="weChat-com-Img" src="@/assets/footer/weChat-com.png" alt="">
						<!-- <img class="ImgAC" src="@/assets/footer/weChatAC.png" alt=""> -->
					</div>
					<div class="tikTok imgBox">
						<img class="tikTokImg" src="@/assets/footer/tikTok.png" alt="">
						<!-- <img class="ImgAC" src="@/assets/footer/weChatAC.png" alt=""> -->
					</div>
				</div>
			</div>
			<div class="center">
				<div class="linkColumn">
					<div class="columnTitle">快速链接</div>
					<div class="columnItem">首页</div>
					<div class="columnItem">产品中心</div>
					<div class="columnItem">智慧门店</div>
					<!-- <div class="columnItem">充电宝</div> -->
					<div class="columnItem">合作伙伴</div>
					<div class="columnItem">关于我们</div>
				</div>
				<!-- <div class="linkColumn">
					<div class="columnTitle">产品列表</div>
					<div class="columnItem">拉卡拉系列</div>
					<div class="columnItem">银盛通系列</div>
					<div class="columnItem">乐刷系列</div>
					<div class="columnItem">立刷系列</div>
				</div> -->
				<div class="linkColumn">
					<div class="columnTitle">联系我们</div>
					<div class="columnItem">加盟热线：400-3333-147</div>
					<div class="columnItem">售后热线：400-8888-669</div>
					<div class="columnItem">广告商合作邮箱：jincaimao@163.com</div>
					<div class="columnItem line"></div>
					<div @click="goToFilingWeb" class="columnItem">皖ICP备2021010738号</div>
					<div class="columnItem">Copyright©安徽进财猫网络科技有限公司All Rights Reserved</div>
				</div>
			</div>
			<div class="right">
				<div class="downloadAppText">下载APP</div>
				<div class="QRcodeImg">
					<!-- <img src="@/assets/footer/iosQRCode.png" alt=""> -->
					<img src="@/assets/footer/AndroidQRCode.png" alt="">
				</div>
				<!-- <div class="QRcodeText">
					<span>IOS版本</span>
					<span>Android版本</span>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Footer",
	components: {},
	props: {},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		goToFilingWeb() {
			window.open("https://beian.miit.gov.cn/#/Integrated/index");
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@/common/css/index.scss";

.footerContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	height: calc(19.0625vw /* 366px -> 19.0625vw */ - 3.125vw /* 60px -> 3.125vw */);
	color: #fff;
	background-color: #131314;
	padding-top: 3.125vw /* 60px -> 3.125vw */;

	.footer {
		display: flex;
		justify-content: space-between;
		width: $content-width /* 1200px -> $content-width */;
		height: 100%;

		

		.left,.right {
			width: 13.0208vw /* 250px -> 13.0208vw */;
			height: 100%;
		}
		
		.left {
			display: flex;
			flex-direction: column;
			gap: 1.5625vw /* 30px -> 1.5625vw */;

			.logo {
				display: inline-block;
				width: 6.25vw /* 120px -> 6.25vw */;
				height: 1.4057vw /* 26.99px -> 1.4057vw */;
			}

			.line {
				display: inline-block;
				width: 6.25vw /* 120px -> 6.25vw */;
				height: .0521vw /* 1px -> .0521vw */;
			}

			.contactWay {
				display: flex;
				justify-content: space-between;
				width: 6.3021vw /* 121px -> 6.3021vw */;

				img {
					width: 1.5625vw /* 30px -> 1.5625vw */;
					height: 1.5625vw /* 30px -> 1.5625vw */;
					cursor: pointer;
				}

				.imgBox {
					position: relative;

					.ImgAC {
						top: 0;
						left: 0;
						position: absolute;
						visibility: hidden;
					}

					.QRcodeBox {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						position: absolute;
						left: 50%;
						top: -14.4792vw /* -278px -> -14.4792vw */;
						transform: translateX(-50%);
						width: 11.4583vw /* 220px -> 11.4583vw */;
						height: 13.4375vw /* 258px -> 13.4375vw */;
						border-radius: .4167vw /* 8px -> .4167vw */;
						background-color: #fff;
						box-shadow: .2083vw /* 4px -> .2083vw */ .2083vw /* 4px -> .2083vw */ .4167vw /* 8px -> .4167vw */ .2083vw /* 4px -> .2083vw */ rgba(0, 0, 0, 0.1);
						visibility: hidden;

						.arrow {
							position: absolute;
							left: 50%;
							bottom: 0%;
							transform: translate(-50%, 100%);
							width: 0;
							height: 0;
							border-top: .5208vw /* 10px -> .5208vw */ solid #fff;
							border-right: .5208vw /* 10px -> .5208vw */ solid transparent;
							border-left: .5208vw /* 10px -> .5208vw */ solid transparent;
						}
						
						img {
							width: 8.9583vw /* 172px -> 8.9583vw */;
							height: 8.9583vw /* 172px -> 8.9583vw */;
						}

						span {
							color: #54585C;
							font-size: .625vw /* 12px -> .625vw */;
						}
					}

					&:hover {
						.ImgAC {
							visibility: visible;
						}
						
						.QRcodeBox {
							visibility: visible;
						}
					}
				}
			}
		}

		.center {
			display: flex;
			// justify-content: space-between;
			width: 30vw /* 576px -> 30vw */;
			padding-right: 7.3438vw /* 141px -> 7.3438vw */;

			.linkColumn {
				margin-right: 6.25vw /* 120px -> 6.25vw */;

				&:last-child {
					margin-right: 0;
				}

				.columnTitle {
					margin-bottom: 1.6667vw /* 32px -> 1.6667vw */;
				}

				.columnItem {
					color: #89898a;
					margin-bottom: .8333vw /* 16px -> .8333vw */;
					cursor: pointer;

					&:hover {
						color: $mainColor;
					}
				}

				.line {
					width: 100%;
					height: .0521vw /* 1px -> .0521vw */;
					background-color: #89898a;
					margin: 1.3021vw /* 25px -> 1.3021vw */ 0;
				}
			}
		}

		.right {
			.downloadAppText {
				margin-bottom: 1.5625vw /* 30px -> 1.5625vw */;
			}

			.QRcodeImg {
				display: flex;
				justify-content: space-between;
				gap: 1.0417vw /* 20px -> 1.0417vw */;

				img {
					width: 5.2083vw /* 100px -> 5.2083vw */;
					height: 5.2083vw /* 100px -> 5.2083vw */;
				}
			}

			.QRcodeText {
				display: flex;
				justify-content: space-between;
				color: #89898a;
				margin-top: 1.0417vw /* 20px -> 1.0417vw */;
				padding: 0 .1563vw /* 3px -> .1563vw */ 0 1.0417vw /* 20px -> 1.0417vw */;
			}
		}
	}
}
</style>
