<template>
	<div class="headerContainer">
		<div class="header">
			<div class="logoBox">
				<img class="logo" src="@/assets/logo.png" alt="">
			</div>
			<div class="menuBox">
				<div @click="changeMenu(item)" v-for="(item, index) in menuList" :key="index" :class="{'menuItem': true, 'activeMenu': item.path == currentPath}">{{ item.name }}</div>
			</div>
			<div class="downloadAndPhoneBox">
				<div class="downloadAPP">
					<span>下载进财猫APP</span>
					<div class="downloadAppQRcode">
						<img src="@/assets/downloadAPPQRcode.png" alt="">
						<span class="text">扫一扫  下载进财猫APP</span>
					</div>
				</div>
				<div class="telephone">
					<img src="@/assets/home/phone.png" alt="">
					<span>400-677-9999</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Header",
	components: {},
	props: {},
	data() {
		return {
			menuList: [
				{
					name: "首页",
					path: "/home",
				},
				{
					name: "产品中心",
					path: "/prodCenter"
				},
				{
					name: "智慧门店",
					path: "/store"
				},
				// {
				// 	name: "充电宝",
				// 	path: "/powerBank"
				// },
				{
					name: "合作伙伴",
					path: "/partner"
				},
				{
					name: "关于我们",
					path: "/about"
				}
			],
			currentPath: '/home'
		};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {
		let hasChooseMenu = sessionStorage.getItem('chooseMenuItem');
		if (hasChooseMenu) {
			this.currentPath = hasChooseMenu;
		}
	},
	methods: {
		changeMenu(item) {
			if (this.currentPath == item.path) {
				return;
			}
			this.currentPath = item.path;
			this.$router.push({
				path: item.path,
			})
			sessionStorage.setItem('chooseMenuItem', item.path)
		}
	},
};
</script>
<style lang="scss" scoped>
@import "~@/common/css/index.scss";

.headerContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 4.1667vw /* 80px -> 4.1667vw */;

	.header {
		display: flex;
		justify-content: space-between;
		width: $content-width /* 1200px -> $content-width */;
		height: 100%;

		.logoBox,.downloadAndPhoneBox {
			width: 13.0208vw /* 250px -> 13.0208vw */;
			height: 100%;
		}

		.logoBox {
			display: flex;
			align-items: center;

			.logo {
				display: inline-block;
				width: 5.2083vw /* 100px -> 5.2083vw */;
				height: 1.1714vw /* 22.49px -> 1.1714vw */;
			}
		}

		.downloadAndPhoneBox {
			position: relative;
			display: flex;
			align-items: center;
			gap: 1.0417vw /* 20px -> 1.0417vw */;

			.downloadAPP,.telephone {
				display: inline-block;
				width: 5.9375vw /* 114px -> 5.9375vw */;
				height: 1.7708vw /* 34px -> 1.7708vw */;
				font-size: .625vw /* 12px -> .625vw */;
				white-space: nowrap;
				cursor: pointer;
			}

			.downloadAPP {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: .2083vw /* 4px -> .2083vw */;
				color: #fff;
				background-color: #3377FF;
				padding: 0 .2083vw /* 4px -> .2083vw */;

				.downloadAppQRcode {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					position: absolute;
					left: 50%;
					top: 2.6042vw /* 50px -> 2.6042vw */;
					transform: translateX(-50%);
					width: 11.4583vw /* 220px -> 11.4583vw */;
					height: 13.4375vw /* 258px -> 13.4375vw */;
					border-radius: .4167vw /* 8px -> .4167vw */;
					background-color: #fff;
					overflow: hidden;
					box-shadow: .2083vw /* 4px -> .2083vw */ .2083vw /* 4px -> .2083vw */ .4167vw /* 8px -> .4167vw */ .2083vw /* 4px -> .2083vw */ rgba(0, 0, 0, 0.1);
					z-index: 10;
					visibility: hidden;

					img {
						width: 8.9583vw /* 172px -> 8.9583vw */;
						height: 8.9583vw /* 172px -> 8.9583vw */;
					}

					.text {
						color: #54585C;
						font-size: .625vw /* 12px -> .625vw */;
						margin-top: .5208vw /* 10px -> .5208vw */;
					}
				}

				&:hover .downloadAppQRcode {
					visibility: visible;
				}
			}

			.telephone {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 7.8125vw /* 150px -> 7.8125vw */;
				border-radius: .2083vw /* 4px -> .2083vw */;
				border: .0521vw /* 1px -> .0521vw */ solid #3377FF;

				img {
					width: 1.0417vw /* 20px -> 1.0417vw */;
					height: 1.0417vw /* 20px -> 1.0417vw */;
					margin-right: .5208vw /* 10px -> .5208vw */;
				}
			}
		}

		.menuBox {
			width: 30vw /* 576px -> 30vw */;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1;
			height: 100%;
			padding-right: 7.3438vw /* 141px -> 7.3438vw */;

			.menuItem {
				display: flex;
				align-items: center;
				height: 100%;
				color: #222222;
				font-size: .8333vw /* 16px -> .8333vw */;

				&:hover {
					color: $mainColor;
					cursor: pointer;
				}
			}
		}
	}

	.activeMenu {
		color: $mainColor !important;
	}
}
</style>
