import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Layout from "@/layout";

const routes = [
	{
		path: "/",
		component: Layout,
		redirect: "/home",
		children: [
			{
				path: "home",
				name: "Home",
				component: () => import("@/views/home/index"),
				meta: { title: "首页" },
			},
			{
				path: "prodCenter",
				name: "prodCenter",
				component: () => import("@/views/prodCenter/index"),
				meta: { title: "产品中心" },
			},
			{
				path: "store",
				name: "Store",
				component: () => import("@/views/store/index"),
				meta: { title: "智慧门店" },
			},
			{
				path: "powerBank",
				name: "PowerBank",
				component: () => import("@/views/powerBank/index"),
				meta: { title: "充电宝" },
			},
			{
				path: "partner",
				name: "Partner",
				component: () => import("@/views/partner/index"),
				meta: { title: "合作伙伴" },
			},
			{
				path: "about",
				name: "About",
				component: () => import("@/views/aboutUs/index"),
				meta: { title: "关于我们" },
			},
			{
				path: "storeJoin",
				name: "StoreJoin",
				component: () => import("@/views/powerBank/apply/index"),
				meta: { title: "门店入驻" },
			},
			{
				path: "agentApply",
				name: "AgentApply",
				component: () => import("@/views/powerBank/apply/index"),
				meta: { title: "成为代理" },
			}
		],
	},
];

const router = new VueRouter({
	routes,
});

const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (to) {
	return VueRouterPush.call(this, to).catch(err => err)
}

export default router;
