<template>
	<div class="LayoutContainer">
		<Header />
		<app-main />
		<Footer />
	</div>
</template>

<script>
import Header from './components/Header'
import AppMain from './components/AppMain.vue'
import Footer from './components/Footer'

export default {
	name: "Layout",
	components: {
		Header,
		AppMain,
		Footer,
	},
	props: {},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
<style lang="scss" scoped>
.LayoutContainer {}
</style>
